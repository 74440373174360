import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment'
import {HttpClient} from '@angular/common/http'
import { lastValueFrom, Observable, Subject, throwError } from 'rxjs';
import {catchError} from 'rxjs/operators';


import {dasa} from '../../classess/dasaClasses';

@Injectable({
  providedIn: 'root'
})
export class commonService {
private subject = new Subject<any>();

  constructor(private http :HttpClient) { }


sendMessage(message:string)
{
  this.subject.next(message)
}
getMessage(): Observable<any>
{
  return this.subject.asObservable();
}
clearMessages()
{
  this.subject.next;
}




  doAPICall(request:any, url:string) :Observable <any>
  {
      return  this.http.post(url ,request)
      .pipe(catchError(this.formatErrors))
  }

  doAPICall_await(request:any, url:string)
  {
      return lastValueFrom(this.http.post(url ,request));

      // try {
      //   const v= lastValueFrom(this.http.post(url ,request));
      //   return v;
        
      // } catch (error) {      
      //   console.log("ERROR", error);
      // }
      
  }

 

  // AddUsers(user:any) :Observable <any>
  // {
  //     return  this.http.post(environment.hosturl ,user)
  //     .pipe(catchError(this.formatErrors))
  // }

  private formatErrors(error :any)
  {
    return throwError(error.error)
  }
}
